<template>
  <div class="relative">
    <div class="vx-navbar-wrapper" :class="classObj">
      <vs-navbar
        class="vx-navbar navbar-custom navbar-skelton"
        :color="navbarColorLocal"
      >
        <!-- SM - OPEN SIDEBAR BUTTON -->
        <feather-icon
          class="sm:inline-flex xl:hidden cursor-pointer mr-1"
          icon="MenuIcon"
          @click.stop="showSidebar"
        ></feather-icon>

        <!-- Credit -->
        <vs-col>
          <vs-row>
            <div v-if="designPermission">
              <p class="credit">{{ $t("Credit") }}: {{ creditDesign }}</p>
            </div>
          </vs-row>
          <vs-row>
            <div v-if="dentalPermission">
              <p class="credit">{{ $t("Credit") }}: {{ creditDental }}</p>
            </div>
          </vs-row>
          <vs-row>
            <div v-if="noktaMarketPermission">
              <p class="credit">
                {{ $t("Credit") }}: {{ creditNoktaMarket.toFixed(2) }}
              </p>
            </div>
          </vs-row>
        </vs-col>

        <vs-spacer />
        <!-- <div>
          <router-link
            class="shoppingCartRouter"
            @click="openOrderList"
            to="shop_cart"
          >
            <feather-icon
              icon="ShoppingCartIcon"
              class="shopCart cursor-pointer ml-4 mr-6 mt-1"
              :badge="getProductCartLength"
            />
          </router-link>
        </div> -->
        <div v-if="shopItems.length">
          <router-link
            class="shoppingCartRouter"
            v-if="designPermission"
            @click="openOrderList"
            to="cart"
          >
            <feather-icon
              icon="ShoppingCartIcon"
              class="cursor-pointer ml-4 mr-6 mt-1"
              :badge="shopItems.length"
            />
          </router-link>
        </div>
        <div v-if="!shopItems.length">
          <div
            class="shoppingCartRouter"
            v-if="designPermission"
            @click="openOrderList"
          >
            <feather-icon
              icon="ShoppingCartIcon"
              class="cursor-pointer ml-4 mr-6 mt-1"
              :badge="shopItems.length"
            />
          </div>
        </div>

        <div
          style="display: none"
          class="shoppingCartRouter"
          v-if="noktaMarketPermission"
        >
          <feather-icon
            icon="ShoppingCartIcon"
            class="cursor-pointer ml-4 mr-6 mt-1"
            :badge="basketItems.length"
            @click="openNoktaMarketBasket"
          />
        </div>

        <!-- NOTIFICATIONS -->
        <!-- <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
          <feather-icon
            icon="BellIcon"
            class="cursor-pointer mt-1 sm:mr-6 mr-2"
            :badge="unreadNotifications.length"
          ></feather-icon>
          <vs-dropdown-menu
            class="notification-dropdown dropdown-custom vx-navbar-dropdown"
          >
            <div class="notification-top text-center p-5 bg-primary text-white">
              <h3 class="text-white">{{ unreadNotifications.length }} New</h3>
              <p class="opacity-75">App Notifications</p>
            </div>

            <VuePerfectScrollbar
              ref="mainSidebarPs"
              class="scroll-area--nofications-dropdown p-0 mb-10"
              :settings="settings"
            >
              <ul class="bordered-items">
                <li
                  v-for="ntf in unreadNotifications"
                  :key="ntf.index"
                  class="flex justify-between px-4 py-4 notification cursor-pointer"
                  @click="readNotification(ntf.index)"
                >
                  <div class="flex items-start">
                    <feather-icon
                      :icon="ntf.icon"
                      :svgClasses="[
                        `text-${ntf.category}`,
                        'stroke-current mr-1 h-6 w-6'
                      ]"
                    ></feather-icon>
                    <div class="mx-2">
                      <span>{{ ntf.index }}</span>
                      <span
                        class="font-medium block notification-title"
                        :class="[`text-${ntf.category}`]"
                        >{{ ntf.title }}</span
                      >
                      <small>{{ ntf.msg }}</small>
                    </div>
                  </div>
                  <small class="mt-1 whitespace-no-wrap">
                    {{ elapsedTime(ntf.time) }}
                  </small>
                </li>
              </ul>
            </VuePerfectScrollbar>
            <div
              class="checkout-footer fixed bottom-0 rounded-b-lg text-primary w-full p-2 font-semibold text-center border border-b-0 border-l-0 border-r-0 border-solid d-theme-border-grey-light cursor-pointer"
            >
              <span>View All Notifications</span>
            </div>
          </vs-dropdown-menu>
        </vs-dropdown> -->

        <!-- USER META -->
        <div
          class="the-navbar__user-meta flex items-center"
          v-if="activeUserInfo"
        >
          <div class="text-right leading-tight hidden sm:block">
            <p class="font-semibold">{{ user_displayName }}</p>
            <small>{{ user_type }}</small>
          </div>
          <vs-dropdown
            vs-custom-content
            vs-trigger-click
            class="cursor-pointer"
          >
            <div class="con-img ml-3">
              <img
                v-if="activeUserImg"
                key="onlineImg"
                :src="activeUserImg"
                alt="user-img"
                width="40"
                height="40"
                class="rounded-full shadow-md cursor-pointer block"
              />
            </div>
            <vs-dropdown-menu class="vx-navbar-dropdown">
              <ul style="min-width: 9rem">
                <!-- PROFILE -->
                <li
                  class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                  @click="$router.push('/user_profile').catch(() => {})"
                >
                  <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
                  <span class="ml-2">{{ $t("Profile") }}</span>
                </li>

                <vs-divider class="m-1"></vs-divider>

                <li
                  class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                  @click="logout"
                >
                  <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
                  <span class="ml-2">{{ $t("Logout") }}</span>
                </li>
              </ul>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
      </vs-navbar>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";

import Api from "@/api/api";

export default {
  name: "the-navbar",
  props: {
    navbarColor: {
      type: String,
      default: "#fff",
    },
  },
  data() {
    return {
      shopItems: this.$store.getters["designMarket/getShopItems"],
      navbarSearchAndPinList: this.$store.state.navbarSearchAndPinList,
      searchQuery: "",
      showFullSearch: false,
      noktaBasketNotificationEnable: false,

      unreadNotifications: [],
      //Fake Notifications
      unreadNotifications2: [
        {
          index: 0,
          title: "New Message",
          msg: "Are your going to meet me tonight?",
          icon: "ActivityIcon",
          time: this.randomDate({ sec: 10 }),
          category: "primary",
        },
        {
          index: 1,
          title: "New Order Recieved",
          msg: "You got new order of goods.",
          icon: "PackageIcon",
          time: this.randomDate({ sec: 40 }),
          category: "success",
        },
        {
          index: 2,
          title: "Server Limit Reached!",
          msg: "Server have 99% CPU usage.",
          icon: "AlertOctagonIcon",
          time: this.randomDate({ min: 1 }),
          category: "danger",
        },
        {
          index: 3,
          title: "New Mail From Peter",
          msg: "Cake sesame snaps cupcake",
          icon: "MailIcon",
          time: this.randomDate({ min: 6 }),
          category: "primary",
        },
        {
          index: 4,
          title: "Bruce's Party",
          msg: "Chocolate cake oat cake tiramisu",
          icon: "CalendarIcon",
          time: this.randomDate({ hr: 2 }),
          category: "warning",
        },
      ],

      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
      autoFocusSearch: false,
      showBookmarkPagesDropdown: false,
    };
  },
  watch: {
    $route() {
      if (this.showBookmarkPagesDropdown)
        this.showBookmarkPagesDropdown = false;
    },
  },
  computed: {
    basketItems() {
      return this.$store.getters["noktaMarket/basket"];
    },
    getProductCartLength() {
      return this.$store.getters.getProductCartLength;
    },
    designPermission() {
      return this.$store.getters.getDesignMarketPermission;
    },
    dentalPermission() {
      return this.$store.getters.getDentalPermission;
    },

    noktaMarketPermission() {
      return this.$store.getters.getNoktaMarketPermission;
    },

    creditDesign() {
      if (
        this.$store.getters.getDesignMarketCredit != null &&
        this.$store.getters.getDesignMarketCredit != 0
      ) {
        return this.$store.getters.getDesignMarketCredit;
      } else {
        return 0;
      }
    },
    creditDental() {
      if (
        this.$store.getters.getDentalCredit != null &&
        this.$store.getters.getDentalCredit != 0
      ) {
        return this.$store.getters.getDentalCredit;
      } else {
        return 0;
      }
    },
    creditNoktaMarket() {
      if (
        this.$store.getters.getNoktaMarketCredit != null &&
        this.$store.getters.getNoktaMarketCredit != 0
      ) {
        return this.$store.getters.getNoktaMarketCredit;
      } else {
        return 0;
      }
    },
    navbarColorLocal() {
      return this.$store.state.theme === "dark" ? "#10163a" : this.navbarColor;
    },
    // HELPER
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth;
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },

    // NAVBAR STYLE
    classObj() {
      if (this.verticalNavMenuWidth == "default") return "navbar-default";
      else if (this.verticalNavMenuWidth == "reduced") return "navbar-reduced";
      else if (this.verticalNavMenuWidth) return "navbar-full";
      return "navbar-default";
    },

    // BOOKMARK & SEARCH
    data() {
      return this.$store.state.navbarSearchAndPinList;
    },
    starredPages() {
      return this.$store.state.starredPages;
    },
    starredPagesLimited: {
      get() {
        return this.starredPages.slice(0, 10);
      },
      set(list) {
        this.$store.dispatch("arrangeStarredPagesLimited", list);
      },
    },
    starredPagesMore: {
      get() {
        return this.starredPages.slice(10);
      },
      set(list) {
        this.$store.dispatch("arrangeStarredPagesMore", list);
      },
    },

    // PROFILE
    activeUserInfo() {
      if (this.$store.state.AppActiveUser.displayName === "") {
        return false;
      }
      return true;
    },
    user_displayName() {
      return this.$store.state.AppActiveUser.displayName;
    },
    activeUserImg() {
      return this.$store.state.AppActiveUser.photoURL;
    },
    user_type() {
      return this.$store.state.AppActiveUser.userRole;
    },
  },
  methods: {
    openNoktaMarketBasket() {
      if (this.basketItems.length > 0) {
        this.$router.push("/nokta_basket").catch(() => {});
      } else {
        if (this.noktaBasketNotificationEnable) {
          return;
        }
        this.noktaBasketNotificationEnable = true;
        this.$vs.notify({
          title: "Warning",
          text: "Your basket is empty",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
        setTimeout(() => {
          this.noktaBasketNotificationEnable = false;
        }, 2500);
      }
    },
    openOrderList() {
      if (this.$store.state.designMarket.shopItems.length > 0) {
        this.$router.push("/cart").catch(() => {});
      } else {
        this.$vs.notify({
          title: this.$t("Warning"),
          text: "Your order list is empty",
          color: "danger",
        });
      }
    },
    readNotification(index) {
      index = this.unreadNotifications.indexOf(
        this.unreadNotifications.find((item) => item.index === index)
      );

      this.unreadNotifications.splice(index, 1);
    },
    showSidebar() {
      this.$store.commit("TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE", true);
    },
    selected(item) {
      this.$router.push(item.url).catch(() => {});
      this.showFullSearch = false;
    },
    actionClicked(item) {
      // e.stopPropogation();
      this.$store.dispatch("updateStarredPage", {
        index: item.index,
        val: !item.highlightAction,
      });
    },
    showNavbarSearch() {
      this.showFullSearch = true;
    },
    showSearchbar() {
      this.showFullSearch = true;
    },
    elapsedTime(startTime) {
      let x = new Date(startTime);
      let now = new Date();
      var timeDiff = now - x;
      timeDiff /= 1000;

      var seconds = Math.round(timeDiff);
      timeDiff = Math.floor(timeDiff / 60);

      var minutes = Math.round(timeDiff % 60);
      timeDiff = Math.floor(timeDiff / 60);

      var hours = Math.round(timeDiff % 24);
      timeDiff = Math.floor(timeDiff / 24);

      var days = Math.round(timeDiff % 365);
      timeDiff = Math.floor(timeDiff / 365);

      var years = timeDiff;

      if (years > 0) {
        return years + (years > 1 ? " Years " : " Year ") + "ago";
      } else if (days > 0) {
        return days + (days > 1 ? " Days " : " Day ") + "ago";
      } else if (hours > 0) {
        return hours + (hours > 1 ? " Hrs " : " Hour ") + "ago";
      } else if (minutes > 0) {
        return minutes + (minutes > 1 ? " Mins " : " Min ") + "ago";
      } else if (seconds > 0) {
        return seconds + (seconds > 1 ? " sec ago" : "just now");
      }

      return "Just Now";
    },
    logout() {
      Api.logout().then((response) => {
        if (response.status == 200) {
          this.$store.dispatch("logout");
          this.$router.push("/login").catch(() => {});
        }
      });
    },
    outside: function () {
      this.showBookmarkPagesDropdown = false;
    },

    // Method for creating dummy notification time
    randomDate({ hr, min, sec }) {
      let date = new Date();

      if (hr) date.setHours(date.getHours() - hr);
      if (min) date.setMinutes(date.getMinutes() - min);
      if (sec) date.setSeconds(date.getSeconds() - sec);

      return date;
    },
  },
  directives: {
    "click-outside": {
      bind: function (el, binding) {
        const bubble = binding.modifiers.bubble;
        const handler = (e) => {
          if (bubble || (!el.contains(e.target) && el !== e.target)) {
            binding.value(e);
          }
        };
        el.__vueClickOutside__ = handler;
        document.addEventListener("click", handler);
      },

      unbind: function (el) {
        document.removeEventListener("click", el.__vueClickOutside__);
        el.__vueClickOutside__ = null;
      },
    },
  },
  components: {
    VuePerfectScrollbar,
  },
};
</script>

<style lang="scss" scoped>
.shoppingCartRouter {
  color: #626262;
}
.credit {
  font-weight: 700;
}
.shopCart {
  .bg-primary {
    background-color: green !important;
  }
}
</style>
