<template>
  <div
    class="layout--main"
    :class="[
      layoutTypeClass,
      navbarClasses,
      footerClasses,
      { 'app-page': isAppPage },
    ]"
  >
    <the-customizer
      v-if="!disableCustomizer"
      :footerType="footerType"
      :hideScrollToTop="hideScrollToTop"
      :navbarType="navbarType"
      :navbarColor="navbarColor"
      :routerTransition="routerTransition"
      @toggleHideScrollToTop="toggleHideScrollToTop"
      @updateFooter="updateFooter"
      @updateNavbar="updateNavbar"
      @updateNavbarColor="updateNavbarColor"
      @updateRouterTransition="updateRouterTransition"
    />

    <v-nav-menu
      :navMenuItems="navMenuItems"
      :logo="navMenuLogo"
      :title="getTitle()"
      parent=".layout--main"
    />

    <div
      id="content-area"
      :class="[contentAreaClass, { 'show-overlay': bodyOverlay }]"
    >
      <div id="content-overlay" />

      <!-- Navbar -->
      <template v-if="mainLayoutType === 'horizontal' && windowWidth >= 1200">
        <the-navbar-horizontal
          :navbarType="navbarType"
          :logo="navMenuLogo"
          :class="[
            { 'text-white': isNavbarDark && !isThemeDark },
            { 'text-base': !isNavbarDark && isThemeDark },
          ]"
        />

        <div style="height: 62px" v-if="navbarType === 'static'"></div>

        <h-nav-menu
          :class="[
            { 'text-white': isNavbarDark && !isThemeDark },
            { 'text-base': !isNavbarDark && isThemeDark },
          ]"
          :navMenuItems="navMenuItems"
        />
      </template>

      <template v-else>
        <the-navbar-vertical
          :navbarColor="navbarColor"
          :class="[
            { 'text-white': isNavbarDark && !isThemeDark },
            { 'text-base': !isNavbarDark && isThemeDark },
          ]"
        />
      </template>
      <!-- /Navbar -->

      <div class="content-wrapper">
        <div class="router-view">
          <div class="router-content">
            <transition :name="routerTransition">
              <div
                v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
                class="router-header flex flex-wrap items-center mb-6"
              >
                <div
                  class="content-area__heading"
                  :class="{
                    'pr-4 border-0 md:border-r border-solid border-grey-light':
                      $route.meta.breadcrumb,
                  }"
                >
                  <h2 class="mb-1">{{ $t(routeTitle) }}</h2>
                </div>

                <!-- BREADCRUMB -->
                <vx-breadcrumb
                  class="ml-4 md:block hidden"
                  v-if="$route.meta.breadcrumb"
                  :route="sendedRouteForTranslate($route)"
                />
              </div>
            </transition>

            <div class="content-area__content">
              <back-to-top
                bottom="5%"
                visibleoffset="500"
                v-if="!hideScrollToTop"
              >
                <vs-button
                  icon-pack="feather"
                  icon="icon-arrow-up"
                  class="shadow-lg btn-back-to-top"
                />
              </back-to-top>

              <transition :name="routerTransition" mode="out-in">
                <router-view @changeRouteTitle="changeRouteTitle"></router-view>
              </transition>
            </div>
          </div>
        </div>
      </div>
      <the-footer />
    </div>
  </div>
</template>

<script>
import BackToTop from "vue-backtotop";
import HNavMenu from "@/layouts/components/horizontal-nav-menu/HorizontalNavMenu.vue";
import navMenuItems from "@/layouts/components/vertical-nav-menu/navMenuItems.js";
import TheCustomizer from "@/layouts/components/customizer/TheCustomizer.vue";
import TheNavbarHorizontal from "@/layouts/components/navbar/TheNavbarHorizontal.vue";
import TheNavbarVertical from "@/layouts/components/navbar/TheNavbarVertical.vue";
import TheFooter from "@/layouts/components/TheFooter.vue";
import themeConfig from "@/../themeConfig.js";
import VNavMenu from "@/layouts/components/vertical-nav-menu/VerticalNavMenu.vue";

//API
import Api from "@/api/api";
import API from "@/api/config";

export default {
  components: {
    BackToTop,
    HNavMenu,
    TheCustomizer,
    TheFooter,
    TheNavbarHorizontal,
    TheNavbarVertical,
    VNavMenu,
  },
  data() {
    return {
      disableCustomizer: themeConfig.disableCustomizer,
      disableThemeTour: themeConfig.disableThemeTour,
      footerType: themeConfig.footerType || "static",
      hideScrollToTop: themeConfig.hideScrollToTop,
      isNavbarDark: false,
      navbarColor: themeConfig.navbarColor || "#fff",
      navbarType: themeConfig.navbarType || "floating",
      navMenuItems: navMenuItems,
      navMenuLogo: require("@/assets/images/logo/logo.png"),
      routerTransition: themeConfig.routerTransition || "none",
      routeTitle: this.$route.meta.pageTitle,
    };
  },
  watch: {
    $route() {
      if (this.$route.name == "dental_order") {
        if (typeof this.$route.query.id != "undefined") {
          this.$route.matched[1].meta.breadcrumb[2].title = "Order Details";
          this.$route.matched[1].meta.pageTitle = "Order Details";
          this.routeTitle = "Order Details";
        } else {
          this.$route.matched[1].meta.breadcrumb[2].title = "New Order";
          this.$route.matched[1].meta.pageTitle = "New Order";
          this.routeTitle = "New Order";
        }
      }

      this.routeTitle = this.$route.meta.pageTitle;
    },
    isThemeDark(val) {
      const color = this.navbarColor == "#fff" && val ? "#10163a" : "#fff";
      this.updateNavbarColor(color);
    },
    "$store.state.mainLayoutType"(val) {
      this.setNavMenuVisibility(val);
      this.disableThemeTour = true;
    },
    windowWidth(val) {
      if (val < 1200) this.disableThemeTour = true;
    },
    verticalNavMenuWidth() {
      this.disableThemeTour = true;
    },
  },
  computed: {
    getShowedWeightMultipier() {
      return this.$store.getters["noktaMarket/showedWeightMultipier"];
    },
    dentalPermission() {
      return this.$store.getters.getDentalPermission;
    },
    bodyOverlay() {
      return this.$store.state.bodyOverlay;
    },
    contentAreaClass() {
      if (this.mainLayoutType === "vertical") {
        if (this.verticalNavMenuWidth == "default")
          return "content-area-reduced";
        else if (this.verticalNavMenuWidth == "reduced")
          return "content-area-lg";
      }
      return "content-area-full";
    },
    footerClasses() {
      return {
        "footer-hidden": this.footerType == "hidden",
        "footer-sticky": this.footerType == "sticky",
        "footer-static": this.footerType == "static",
      };
    },
    isAppPage() {
      return this.$route.path.includes("/apps/") ? true : false;
    },
    isThemeDark() {
      return this.$store.state.theme == "dark";
    },
    layoutTypeClass() {
      return `main-${this.mainLayoutType}`;
    },
    mainLayoutType() {
      return this.$store.state.mainLayoutType;
    },
    navbarClasses() {
      return {
        "navbar-hidden": this.navbarType == "hidden",
        "navbar-sticky": this.navbarType == "sticky",
        "navbar-static": this.navbarType == "static",
        "navbar-floating": this.navbarType == "floating",
      };
    },
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth;
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },
  },
  methods: {
    sendedRouteForTranslate(route) {
      var newRoute = route;

      newRoute.meta.breadcrumb.forEach((element, index) => {
        if (this.$t(element.title) != "") {
          newRoute.meta.breadcrumb[index].title = this.$t(element.title);
        }
      });

      return newRoute;
    },
    getTitle() {
      if (this.dentalPermission) {
        return "DentaFab";
      } else {
        return "NovaFab";
      }
    },
    changeRouteTitle(title) {
      this.routeTitle = title;
    },
    updateNavbar(val) {
      if (val == "static") this.updateNavbarColor("#fff");
      this.navbarType = val;
    },
    updateNavbarColor(val) {
      this.navbarColor = val;
      if (val == "#fff") this.isNavbarDark = false;
      else this.isNavbarDark = true;
    },
    updateFooter(val) {
      this.footerType = val;
    },
    updateRouterTransition(val) {
      this.routerTransition = val;
    },
    setNavMenuVisibility(layoutType) {
      if (
        (layoutType === "horizontal" && this.windowWidth >= 1200) ||
        (layoutType === "vertical" && this.windowWidth < 1200)
      ) {
        this.$store.commit("TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE", false);
        this.$store.dispatch("updateVerticalNavMenuWidth", "no-nav-menu");
      } else {
        this.$store.commit("TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE", true);
      }
    },
    toggleHideScrollToTop(val) {
      this.hideScrollToTop = val;
    },
    handleInfoRequest(status, data) {
      this.$vs.loading.close();
      if (status == 200) {
        this.$i18n.locale = data.language;
        this.$store.dispatch("setLocale", data.language);
        this.$store.dispatch("setUserInfo", data);
        if (data.nokta_market_gold_type) {
          this.getShowedWeightMultipier.forEach((element) => {
            if (element.weightType.slice(0, 2) == data.nokta_market_gold_type) {
              this.$store.commit(
                "noktaMarket/CHANGE_SELECTED_WEIGHT_MULTIPIER",
                element
              );
            }
          });
        } else {
          this.$store.commit(
            "noktaMarket/CHANGE_SELECTED_WEIGHT_MULTIPIER",
            this.getShowedWeightMultipier[0]
          );
        }
      } else if (status == 0) {
        this.$vs.notify({
          color: "danger",
          title: "Network Error! Please check your internet connection.",
          text: "Please try again.",
        });
      } else if (status == 403) {
        this.$router.push("/login").catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
        this.$store.dispatch("logout");
      } else {
        this.$store.dispatch("setUserInfo");
      }
    },

    controlToken() {
      this.$vs.loading({ text: this.$t("Loading_Please_Wait") });
      Api.get(API.BASEURL + API.ME, this.handleInfoRequest);
    },
    handleTokenRequest(status, data) {
      this.$vs.close();
      if (status == 401) {
        Api.refreshTokenRequest();
        this.controlToken();
      } else if (status == 0) {
        this.$vs.notify({
          color: "danger",
          title: "Network Error!",
          text: "Please try again.",
        });
      } else {
        this.$router.push("/login").catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
        this.$store.dispatch("logout");
      }
    },
  },
  created() {
    const color =
      this.navbarColor == "#fff" && this.isThemeDark
        ? "#10163a"
        : this.navbarColor;
    this.updateNavbarColor(color);
    this.setNavMenuVisibility(this.$store.state.mainLayoutType);
  },
  beforeCreate() {
    if (localStorage.getItem("username") != null) {
      this.$store.dispatch("initLocalData");
    }

    let noktaBasket = JSON.parse(localStorage.getItem("noktaBasket"));

    if (noktaBasket != null) {
      this.$store.dispatch("noktaMarket/initBasket");
    }

    var shopItems = JSON.parse(localStorage.getItem("shop"));

    if (shopItems != null) {
      this.$store.dispatch("designMarket/firstAddedShopList", shopItems);
    }
  },
  beforeMount() {
    this.controlToken();
  },
};
</script>

<style lang="scss">
.vs-pagination--mb {
  min-width: -webkit-fill-available;
  justify-content: center !important;
}
</style>
