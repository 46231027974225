/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  Strucutre:
          url     => router path
          name    => name to display in sidebar
          slug    => router path name
          icon    => Feather Icon component/icon name
          tag     => text to display on badge
          tagColor  => class to apply on badge element
          i18n    => Internationalization
          submenu   => submenu of current item (current item will become dropdown )
                NOTE: Submenu don't have any icon(you can add icon if u want to display)
          isDisabled  => disable sidebar item/group
==========================================================================================*/

export default [
  {
    url: "/",
    name: "Dashboard",
    slug: "Home",
    icon: "HomeIcon",
    i18n: "Dashboard",
    auth: ""
  },
  {
    header: "Design Market",
    auth: "design",
    i18n: "Design_Market",
    items: [
      // {
      //   url: "/design_market",
      //   name: "Store",
      //   slug: "design_market",
      //   icon: "ShoppingBagIcon",
      //   i18n: "Store",
      //   auth: "design"
      // },
      {
        url: "/design_orders",
        name: "Orders",
        slug: "design_orders",
        icon: "BriefcaseIcon",
        i18n: "Orders",
        auth: "design"
      },
      {
        url: "/plates",
        name: "Plates",
        slug: "plates",
        icon: "CodepenIcon",
        i18n: "Plates",
        auth: "design"
      }
      // {
      //   url: "/design_credit",
      //   name: "Credit",
      //   slug: "design_credit",
      //   icon: "CreditCardIcon",
      //   i18n: "Credit",
      //   auth: "design"
      // }
    ]
  },
  {
    header: "Dental",
    auth: "dental",
    i18n: "Dental",
    items: [
      {
        url: "/dental_orders",
        name: "Orders",
        slug: "dental_orders",
        icon: "ShoppingBagIcon",
        i18n: "Orders",
        auth: "dental"
      },
      {
        url: "/dental_file",
        name: "Files",
        slug: "dental_file",
        icon: "FileTextIcon",
        i18n: "Files",
        auth: "dental"
      },
      {
        url: "/dental_credit",
        name: "Credit",
        slug: "dental_credit",
        icon: "CreditCardIcon",
        i18n: "Credit",
        auth: "dental"
      }
    ]
  },
  {
    header: "Provider",
    auth: "provider",
    i18n: "Provider",
    items: [
      {
        url: "/provider_orders",
        name: "Orders",
        slug: "provider_orders",
        icon: "ShoppingBagIcon",
        i18n: "Orders",
        auth: "provider"
      },
      {
        url: "/provider_history",
        name: "History",
        slug: "provider_history",
        icon: "CalendarIcon",
        i18n: "History",
        auth: "provider"
      }
    ]
  },

  {
    header: "Nokta Market",
    auth: "noktaMarket",
    i18n: "Nokta_Market",
    items: [
      // {
      //   url: "/nokta_market",
      //   name: "Store",
      //   slug: "nokta_products",
      //   icon: "ShoppingBagIcon",
      //   i18n: "Store",
      //   auth: "noktaMarket"
      // },
      {
        url: "/nokta_orders",
        name: "Orders",
        slug: "nokta_orders",
        icon: "BriefcaseIcon",
        i18n: "Orders",
        auth: "noktaMarket"
      }
      // {
      //   url: "/nokta_credits",
      //   name: "Credits",
      //   slug: "nokta_credits",
      //   icon: "CreditCardIcon",
      //   i18n: "Credits",
      //   auth: "noktaMarket"
      // }
    ]
  },
  {
    header: "Nokta Market Admin",
    auth: "noktaAdmin",
    i18n: "Nokta_Market_Admin",
    items: [
      {
        url: "/nokta_products",
        name: "Products",
        slug: "nokta_products",
        icon: "FolderPlusIcon",
        i18n: "Products",
        auth: "noktaAdmin"
      },
      {
        url: "/unapproved_products",
        name: "Unapproved",
        slug: "unapproved_products",
        icon: "AlertOctagonIcon",
        i18n: "Unapproved",
        auth: "noktaAdmin"
      },
      {
        url: "/nokta_admin_customer2",
        name: "Customer",
        slug: "nokta_admin_customer2",
        icon: "UsersIcon",
        i18n: "Customer",
        auth: "noktaAdmin"
      },
      {
        url: "/nokta_admin_credits",
        name: "Credits",
        slug: "nokta_admin_credits",
        icon: "CreditCardIcon",
        i18n: "Credits",
        auth: "noktaAdmin"
      }
      // {
      //   url: "/nokta_admin_orders",
      //   name: "Orders",
      //   slug: "nokta_admin_orders",
      //   icon: "BriefcaseIcon",
      //   i18n: "Orders",
      //   auth: "noktaAdmin"
      // }
    ]
  },
  {
    header: "Control Panel",
    auth: "",
    items: [
      {
        url: "/machines",
        name: "Machines",
        slug: "machines",
        icon: "HardDriveIcon",
        i18n: "Machines",
        auth: ""
      },
      {
        url: "/print_logs",
        name: "Print Logs",
        slug: "print_logs",
        icon: "CloudIcon",
        i18n: "Print Logs",
        auth: ""
      }
    ]
  }
  // {
  //   header: "Shop",
  //   auth: "",
  //   items: [
  //     {
  //       url: "/products",
  //       name: "Products",
  //       slug: "machines",
  //       icon: "BriefcaseIcon",
  //       i18n: "Products",
  //       auth: ""
  //     },
  //     {
  //       url: "/shop_orders",
  //       name: "Orders",
  //       slug: "shop_orders",
  //       icon: "ShoppingBagIcon",
  //       i18n: "Orders",
  //       auth: ""
  //     },
  //     {
  //       url: "/mail_order",
  //       name: "Mail Order",
  //       slug: "mail_order",
  //       icon: "SendIcon",
  //       i18n: "Mail Order",
  //       auth: ""
  //     }
  //   ]
  // }
];
