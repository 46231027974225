<template functional>
  <footer class="the-footer flex-wrap justify-between" :class="classes">
    <span>
      COPYRIGHT @ {{ new Date().getFullYear() }}
      <a href="https://3bfab.com" target="_blank" rel="nofollow">3BFab</a>, All
      rights Reserved
      <a
        href="https://novahub-dev.s3.eu-central-1.amazonaws.com/documents/mesafeli+satis.pdf"
        target="_blank"
        rel="nofollow"
        >Mesafeli Satış Sözleşmesi</a
      >
      |
      <a
        href="https://novahub-dev.s3.eu-central-1.amazonaws.com/documents/Novafab.pdf"
        target="_blank"
        rel="nofollow"
        >KVKK</a
      >

      |
      <a
        href="https://novahub-dev.s3.eu-central-1.amazonaws.com/documents/cerez.pdf"
        target="_blank"
        rel="nofollow"
        >Çerez Politikası</a
      >

      |
      <a href="http://novafab.com/tr/biz-kimiz/" target="_blank" rel="nofollow"
        >Hakkımızda</a
      >
    </span>
    <span class="md:flex hidden items-center">
      <feather-icon
        icon="HeartIcon"
        svgClasses="stroke-current text-danger w-6 h-6"
        class="ml-2"
      />
    </span>
  </footer>
</template>

<script>
export default {
  name: "the-footer",
  props: {
    classes: {
      type: String
    }
  }
};
</script>
